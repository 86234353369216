import { FormInput } from "@components/FormInput";
import { UseFormReturn } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Button } from "@components/Button";
import { useState } from "react";
import { Edit } from "@mui/icons-material";

export const CRMAccountSettings = (props: {
  disabled?: boolean;
  onSave: (values) => void;
  form: UseFormReturn;
  isLoading: boolean;
}) => {
  const {
    getValues,
    formState: { isValid },
    trigger,
    setValue,
    control
  } = props.form;
  const [isEditing, setIsEditing] = useState(false);
  const validateRange = (currentValue: string, prevValue: string) => {
    const currVal =
      currentValue !== undefined ? parseInt(currentValue?.split("-")[0]) : null;
    const currValEnd =
      currentValue !== undefined ? parseInt(currentValue?.split("-")[1]) : null;
    const prevVal =
      prevValue !== undefined ? parseInt(prevValue?.split("-")[1]) : null;

    if (!currentValue.includes("-") || currentValue.split("-").length > 2) {
      return "Invalid Format";
    }
    if (prevVal !== null && currVal !== null && currVal <= prevVal) {
      return `This value must be greater than ${prevVal}`;
    }
    if (currValEnd !== null && currValEnd < currVal!) {
      return "This value must be greater than start value";
    }

    return true;
  };

  return (
    <>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            variant="permissionNames"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              letterSpacing: "10%",
              lineHeight: "14.52px",
              opacity: "50%"
            }}
          >
            ACCOUNT SETTINGS
          </Typography>
        </Grid>
        <Grid item>
          {isEditing ? (
            <Button
              variant="admin-primary"
              onClick={() => {
                console.log("values", getValues("rating"));
                const data = getValues("rating");
                const formattedValue = Object.entries(data).map(
                  ([type, range]) => {
                    const [start, end] = (range as string).split(/[-\s+]+/);
                    return {
                      type,
                      start: parseInt(start, 10),
                      end: end === "" ? "+" : parseInt(end, 10)
                    };
                  }
                );
                props.onSave(formattedValue);
              }}
              disabled={!isValid || props.isLoading}
              isLoading={props.isLoading}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="admin-primary"
              onClick={() => setIsEditing(true)}
              startIcon={<Edit />}
            >
              Edit
            </Button>
          )}
        </Grid>
      </Grid>

      <HeaderUnderLine width="100%" />
      <Grid
        container
        direction="row"
        columnSpacing={3}
        rowSpacing={1.5}
        marginTop="24px"
      >
        <Grid item xs={12} md={6}>
          <StyledFormLabel required style={{ fontWeight: 600 }}>
            Rating
          </StyledFormLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledFormLabel required style={{ fontWeight: 600 }}>
            No. of Yearly Athlete Registrations
          </StyledFormLabel>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="orgRatingAAA"
            control={control}
            enteredValue="AAA"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="rating.AAA"
            control={control}
            rules={{
              required: "Rating is Required"
            }}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="orgRatingAA"
            control={control}
            enteredValue="AA"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="rating.AA"
            control={control}
            rules={{
              required: "Rating is Required",
              validate: (value) => validateRange(value, getValues("rating.A"))
            }}
            disabled={!isEditing}
            onBlur={() => {
              setValue(
                "rating.AAA",
                `${parseInt(getValues("rating.AA")?.split("-")[1]) + 1} +`
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="orgRatingA"
            control={control}
            enteredValue="A"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="rating.A"
            control={control}
            rules={{
              required: "Rating is Required",
              validate: (value) => validateRange(value, getValues("rating.B"))
            }}
            disabled={!isEditing}
            onBlur={() => {
              if (getValues("rating.AA") && getValues("rating.AA") != "")
                trigger("rating.AA");
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="orgRatingB"
            control={control}
            enteredValue="B"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="rating.B"
            control={control}
            rules={{
              required: "Rating is Required",
              format: (value) =>
                /^[0-9]+-[0-9]+$/.test(value) ||
                "Value must be in format start-end",
              validate: (value) => validateRange(value, getValues("rating.C"))
            }}
            disabled={!isEditing}
            onBlur={() => {
              if (getValues("rating.A") && getValues("rating.A") != "")
                trigger("rating.A");
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="orgRatingC"
            control={control}
            enteredValue="C"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            type="text"
            label=""
            name="rating.C"
            control={control}
            rules={{
              required: "Rating is Required"
            }}
            disabled={!isEditing}
            onBlur={() => {
              if (getValues("rating.B") && getValues("rating.B") != "")
                trigger("rating.B");
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
