import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Add } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  styled
} from "@mui/material";
import { SUFFIX_OPTIONS } from "@utils/constants";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { UseFormReturn } from "react-hook-form";
import colors from "theme/colors";
import { RelatedLinks } from "../components/RelatedLinks";
import { RelatedAccounts } from "./components/RelatedAccounts";
import { FormCheckbox } from "@components/FormCheckbox";
import { AddressAutocomplete } from "@components/AddressAutocomplete";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Loader } from "@components/crud/Loader";
import { createCRMActivity } from "@services/Network";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { enqueueSnackbar } from "notistack";
import CallIcon from "@mui/icons-material/Call";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import { useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { capitalize } from "@utils/capitalize";
import { DeleteIcon } from "@components/Icons";
import { ToolTip } from "@components/ToolTip";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    style={{
      minHeight: "36px",
      maxHeight: "36px"
    }}
  />
))({});

interface StyledTabProps {
  label: string;
  icon?;
  iconPosition?;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  textTransform: "none",
  color: "#0F0F0F",
  backgroundColor: "#F3F4F7",
  fontWeight: "lighter",
  maxHeight: "36px",
  minHeight: "36px",
  "&.Mui-selected": {
    color: "#0F0F0F",
    backgroundColor: "#E8EEFF"
  }
}));

export const ContactDetailsForm = ({
  disabled,
  form,
  relatedAccounts,
  setRelatedAccounts,
  relatedLinks,
  setRelatedLinks,
  homeLocationSameAs,
  setHomeLocationSameAs,
  isHomeLocationSameAs,
  setIsHomeLocationSameAs,
  isEdit,
  organizationId
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  relatedAccounts;
  setRelatedAccounts;
  relatedLinks;
  setRelatedLinks;
  homeLocationSameAs;
  setHomeLocationSameAs;
  isHomeLocationSameAs;
  setIsHomeLocationSameAs;
  isEdit?: boolean;
  organizationId?: string;
}) => {
  dayjs.extend(timezone);
  console.log(isEdit);
  const { contactId } = useParams();
  const mutation = createCRMActivity();
  const user = useRecoilValue(profileAtom);
  const {
    setCallerDetails,
    connectToCall,
    setCallAccepted,
    callInstance,
    setEmailTo
  } = useContext(CallEmailTemplateContext);
  const { control, setValue, trigger, getValues } = form;
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [accountToDeleteIndex, setAccountToDeleteIndex] = useState(null);
  const setAccountIndexValue = (index, key, value) => {
    const accounts = relatedAccounts;
    accounts[index][key] = value;
    setRelatedAccounts([...accounts]);
  };

  useEffect(() => {
    if (isHomeLocationSameAs && homeLocationSameAs) {
      setValue(
        "homeLocation",
        relatedAccounts.find((acc) => acc.accountId === homeLocationSameAs)
          .workLocation,
        {
          shouldDirty: true,
          shouldValidate: true
        }
      );
    }
  }, [homeLocationSameAs, isHomeLocationSameAs]);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;

    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    let address: string = "";

    if (address1 && !address1.includes("undefined")) address = `${address1}, `;
    if (city?.long_name && city?.long_name != "undefined")
      address += `${city?.long_name}, `;
    if (state?.short_name && state?.short_name != "undefined")
      address += `${state?.short_name}, `;
    if (zip?.long_name && zip?.long_name != "undefined")
      address += `${zip?.long_name}, `;
    if (country?.short_name && country?.short_name != "undefined")
      address += `${country?.short_name}`;
    setValue("homeLocation", address);
  };

  return (
    <Grid
      data-testid="contact-detail-form"
      container
      direction="column"
      spacing="25px"
    >
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={3}>
          <FormInput
            name="firstName"
            control={control}
            rules={{ required: "First Name is required" }}
            label="First Name"
            type="text"
            required={true}
            disabled={disabled}
            onChange={(e) =>
              setValue(
                "firstName",
                capitalize((e as ChangeEvent<HTMLInputElement>).target.value)
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            name="middleName"
            control={control}
            label="Middle Name"
            type="text"
            disabled={disabled}
            onChange={(e) =>
              setValue(
                "middleName",
                capitalize((e as ChangeEvent<HTMLInputElement>).target.value)
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            name="lastName"
            control={control}
            rules={{ required: "Last Name is required" }}
            label="Last Name"
            type="text"
            required={true}
            disabled={disabled}
            onChange={(e) =>
              setValue(
                "lastName",
                capitalize((e as ChangeEvent<HTMLInputElement>).target.value)
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name="suffix"
            control={control}
            label="Suffix"
            options={SUFFIX_OPTIONS}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} direction="column">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          marginBottom="-15px"
        >
          <Grid item xs={4}>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "10%",
                lineHeight: "14.52px",
                opacity: "50%"
              }}
            >
              PERSONAL CONTACT DETAILS
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing="10px"
            xs={8}
            justifyContent="flex-end"
          >
            <Grid item>
              <FormCheckbox
                control={control}
                name={`isHomeLocationSameAs`}
                label="Home Location is the same as "
                disabled={disabled || !relatedAccounts?.[0]?.accountId}
                onChange={(e) => {
                  if (e.target.checked && !homeLocationSameAs)
                    setHomeLocationSameAs(relatedAccounts[0].accountId);
                  setIsHomeLocationSameAs(e.target.checked);
                }}
              />
            </Grid>
            <Grid item marginTop="10px" alignItems="center">
              <FormSelect
                sx={{
                  height: "25px"
                }}
                name="homeLocationSameAs"
                disabled={disabled}
                value={homeLocationSameAs}
                options={
                  relatedAccounts
                    ?.filter((acc) => acc.accountId)
                    .map((acc) => ({
                      label: acc.account,
                      value: acc.accountId
                    })) || []
                }
                onChange={(e) => {
                  setHomeLocationSameAs(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <HeaderUnderLine width="100%" />
      </Grid>

      <Grid item xs={12}>
        <AddressAutocomplete
          control={control}
          label="Home Address"
          name={`homeLocation`}
          disabled={disabled || isHomeLocationSameAs}
          trigger={trigger}
          selectSuggestion={selectAddressSuggestion}
          required={false}
          rules={undefined}
        />
      </Grid>
      <Grid item container direction="row" spacing="15px">
        <Grid item xs={6}>
          <FormInput
            label="Personal Phone"
            disabled={disabled}
            control={control}
            name={`homePhone`}
            type="tel"
            InputProps={{
              ...(disabled &&
                !organizationId && {
                  endAdornment: (
                    <Loader align="flex-end" isLoading={mutation.isLoading}>
                      <IconButton
                        disabled={
                          !getValues("homePhone") ||
                          getValues("homePhone") == ""
                        }
                        onClick={async () => {
                          const account = relatedAccounts[selectedAccount];
                          const contact = {
                            value: contactId,
                            label:
                              getValues("firstName") +
                              " " +
                              getValues("lastName"),
                            details: {
                              phone: getValues("homePhone")
                            },
                            isPrimary: account?.isPrimaryContact
                          };

                          if (callInstance) {
                            enqueueSnackbar("You are already on a call!", {
                              variant: "error"
                            });
                            return;
                          }
                          mutation.mutate(
                            {
                              data: {
                                accountId: account?.accountId,
                                type: "CALL",
                                relatesTo: "CONTACT",
                                contactId: contact?.value,
                                date: new Date(),
                                timezone: dayjs.tz.guess(),
                                direction: "outbound",
                                status: "LIVE",
                                outcome: "CONNECTED_WITH_CONTACT",
                                internalParticipants: [user?.userId as string],
                                externalParticipants: [
                                  {
                                    phone: contact?.details.phone || "",
                                    contactId: contact?.value
                                  }
                                ]
                              }
                            },
                            {
                              onSuccess: (data) => {
                                setCallerDetails({
                                  activityId: data?.data?.activity?.activityId,
                                  relatesTo: "CONTACT",
                                  contactId: contact?.value,
                                  accountId: account?.accountId,
                                  accountName: account?.account,
                                  callDirection: "outbound",
                                  location: account?.workLocation,
                                  phone: contact?.details.phone,
                                  phoneType: "PERSONAL",
                                  isConnected: account?.accountId
                                    ? true
                                    : false,
                                  contact: {
                                    contactName: contact?.label,
                                    contactPhone: contact?.details.phone,
                                    isPrimary: contact?.isPrimary
                                  }
                                });
                                connectToCall(
                                  contact?.details.phone as string,
                                  data?.data?.activity?.activityId
                                );
                                setCallAccepted(true);
                              }
                            }
                          );
                        }}
                      >
                        <ToolTip title="Click to Call">
                          <CallIcon style={{ color: "#007AFF" }} />
                        </ToolTip>
                      </IconButton>
                    </Loader>
                  )
                })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Personal Email"
            control={control}
            disabled={disabled}
            name={`personalEmail`}
            type="text"
            rules={{
              validate: (value) => {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!regex.test(value) && value && value != "") {
                  return "Invalid Email";
                }
              }
            }}
            InputProps={{
              ...(disabled &&
                !organizationId && {
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setEmailTo({
                          to: getValues("personalEmail"),
                          name:
                            getValues("firstName") +
                            " " +
                            getValues("lastName"),
                          accountId:
                            relatedAccounts[selectedAccount]?.accountId,
                          relatesTo: "CONTACT",
                          contactId: contactId,
                          accountName: relatedAccounts[selectedAccount]?.name
                        })
                      }
                    >
                      <EmailIcon style={{ color: "#007AFF" }} />
                    </IconButton>
                  )
                })
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="15px">
        <Grid item xs={6}>
          <FormInput
            label="WhatsApp Number"
            control={control}
            disabled={disabled}
            name={`homeWhatsappNumber`}
            type="tel"
            country={{
              countryId: "US",
              dialCode: "1"
            }}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} direction="column">
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "10%",
                lineHeight: "14.52px",
                opacity: "50%"
              }}
            >
              PERSONAL RELATED LINKS
            </Typography>
          </Grid>
          {!disabled && (
            <Grid item>
              <Button
                startIcon={<Add />}
                disabled={disabled}
                style={{
                  textTransform: "capitalize",
                  color: colors.primary.main
                }}
                onClick={() => {
                  const rl = relatedLinks;
                  setRelatedLinks([
                    ...rl,
                    { name: "FACEBOOK", url: "", otherName: "" }
                  ]);
                }}
              >
                Add Link
              </Button>
            </Grid>
          )}
        </Grid>
        <HeaderUnderLine width="100%" />
      </Grid>
      <Grid item container direction="column">
        <RelatedLinks
          control={control}
          relatedLinks={relatedLinks}
          disabled={disabled}
          setRelatedLinks={setRelatedLinks}
        />
      </Grid>

      <Grid item container xs={12} direction="column">
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "10%",
                lineHeight: "14.52px",
                opacity: "50%"
              }}
            >
              RELATED ACCOUNTS
            </Typography>
          </Grid>
          {!disabled && (
            <Grid item>
              <Button
                startIcon={<Add />}
                disabled={disabled}
                style={{
                  textTransform: "capitalize",
                  color: colors.primary.main
                }}
                onClick={() => {
                  const accs = relatedAccounts;
                  setRelatedAccounts([
                    ...accs,
                    {
                      account: "New Account",
                      jobTitle: "",
                      isPrimaryContact: false,
                      workLocation: "",
                      workPhone: "",
                      workEmail: "",
                      accountPhone: "",
                      accountEmail: ""
                    }
                  ]);
                }}
              >
                Add Account
              </Button>
            </Grid>
          )}
        </Grid>
        <HeaderUnderLine width="100%" />
      </Grid>
      <Grid item marginTop="-25px" style={{ maxWidth: "100%" }}>
        <StyledTabs
          value={selectedAccount}
          onChange={(event, newValue) => {
            setSelectedAccount(newValue);
          }}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-scrollButtons.Mui-disabled": {
              display: "none"
            },
            "& .MuiTabs-scrollButtons": {
              border: "1px solid #E5E5E5"
            }
          }}
        >
          {relatedAccounts.map((acc, idx) => {
            return (
              <StyledTab
                label={acc.account}
                key={idx}
                icon={
                  relatedAccounts.length > 1 && !disabled ? (
                    <IconButton
                      disabled={disabled}
                      onClick={() => {
                        setAccountToDelete(acc);
                        setAccountToDeleteIndex(idx);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : undefined
                }
                iconPosition="end"
              />
            );
          })}
        </StyledTabs>
      </Grid>
      <Grid item>
        {relatedAccounts[selectedAccount] && (
          <RelatedAccounts
            key={selectedAccount}
            organizationId={organizationId}
            relatedAccount={relatedAccounts[selectedAccount]}
            control={control}
            disabled={disabled}
            selectedAccountIndex={selectedAccount}
            setAccountIndexValue={setAccountIndexValue}
            defaultSearchValue={
              relatedAccounts[selectedAccount].accountId
                ? relatedAccounts[selectedAccount].account
                : undefined
            }
            onOptionClick={(isNewAccount, selectedAccountDetails) => {
              if (!isNewAccount) {
                const accs = relatedAccounts;
                accs[selectedAccount] = selectedAccountDetails;
                console.log(accs, selectedAccount);
                setRelatedAccounts([...accs]);
              }
            }}
            contact={{
              contactName: getValues("firstName") + " " + getValues("lastName"),
              contactPhone: getValues("homePhone"),
              contactId: contactId as string
            }}
            setValue={setValue}
          />
        )}
      </Grid>

      <ConfirmationDialog
        open={!!accountToDelete}
        title="Delete Account?"
        body={`Are you sure you want to delete the account ${accountToDelete?.account} from this contact?`}
        onConfirm={() => {
          const accs = relatedAccounts;
          accs.splice(accountToDeleteIndex, 1);
          setSelectedAccount(0);
          setRelatedAccounts([...accs]);
          setAccountToDelete(null);
          setAccountToDeleteIndex(null);
        }}
        onCancel={() => {
          setAccountToDelete(null);
          setAccountToDeleteIndex(null);
        }}
        icon="error"
        confirmBtnVariant="admin-error"
      />
    </Grid>
  );
};
