import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/system/Unstable_Grid";
import { Button, ButtonGroup } from "@mui/material";
import Overview from "./components/Overview";
import { TeamBreakdown } from "./components/TeamBreakdown";
import {
  adminDirectoryGet,
  AdminEpicEpicIdGetPlatform,
  AdminEpicEpicIdGetResponse,
  AdminEpicEpicIdGetResponseBreakdown,
  ModelEpicIssue,
  useAdminEpicEpicIdDelete,
  useAdminEpicEpicIdGet,
  useAdminEpicEpicIdTeamBreakdownGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { hasPermission } from "@services/Casbin";

export const EpicView = () => {
  const buttonGroups = ["Overview", "Team Breakdown"];
  const [tab, setTab] = useState<"Overview" | "Team Breakdown">("Overview");
  const [platformFilter, setPlatformFilter] = useState<
    AdminEpicEpicIdGetPlatform | "ALL"
  >("ALL");
  const [resourceFilter, setResourceFilter] = useState<string>("ALL");
  const [permissions, setPermissions] = useState({
    edit: false,
    delete: false
  });
  const navigate = useNavigate();
  const { epicId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { control } = form;
  const [epicToDelete, setEpicToDelete] =
    useState<AdminEpicEpicIdGetResponse | null>(null);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("tech.epics", "VIEW");
      const edit = await checkPermission("tech.epics", "EDIT");
      const del = await checkPermission("tech.epics", "DELETE");
      setPermissions({
        edit,
        delete: del
      });
      if (!view) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const { data: companyDirectory, isLoading: isCompanyDirectoryLoading } =
    useQuery(["companyDirectory"], () => adminDirectoryGet(), {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    });

  const { data: epic, isLoading: epicLoading } = useAdminEpicEpicIdGet(
    epicId!,
    {
      ...(platformFilter !== "ALL" && { platform: platformFilter }),
      ...(resourceFilter !== "ALL" && { resourceId: resourceFilter })
    },
    {
      query: {
        queryKey: ["epic", epicId, platformFilter, resourceFilter]
      }
    }
  );

  const { data: teamBreakdown, isLoading: teamBreakdownLoading } =
    useAdminEpicEpicIdTeamBreakdownGet(epicId!);

  const { mutateAsync, isLoading } = useAdminEpicEpicIdDelete();

  const onConfirmDelete = async () => {
    if (!epicToDelete?.epicId) return;
    try {
      await mutateAsync({ epicId: epicToDelete?.epicId });
      enqueueSnackbar("Epic deleted successfully", { variant: "success" });
      setEpicToDelete(null);
      navigate("/epics");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete epic", {
        variant: "error"
      });
      setEpicToDelete(null);
    }
  };

  useEffect(() => {
    if (epic?.data) {
      form.reset({
        name: epic.data.name,
        description: epic.data.description
      });
    }
  }, [epic]);

  const resourcesOptions = useMemo(() => {
    let options: Array<{ label: string; value: string }> = [];

    if (companyDirectory?.data.persons && epic?.data.resources) {
      options = companyDirectory.data.persons
        .filter(
          (resource) =>
            resource.sportsgravyUser &&
            epic?.data.resources?.includes(
              resource?.sportsgravyUser?.jiraAccountId as string
            )
        )
        .map((resource) => ({
          label: `${resource.firstName} ${resource.lastName}`,
          value: resource?.sportsgravyUser?.jiraAccountId as string
        }));
    }

    options.unshift({ label: "All Resource", value: "ALL" });
    return options;
  }, [companyDirectory?.data, epic?.data.resources]);

  const platformOptions = useMemo(() => {
    let options: Array<{ label: string; value: string }> = [];
    options =
      epic?.data.platforms?.map((platform) => ({
        label: platform,
        value: platform
      })) || [];
    options.unshift({ label: "All Platform", value: "ALL" });
    return options;
  }, [epic?.data.platforms]);

  return (
    <Container>
      <Toolbar
        title="View Epic"
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/epics/${epicId}/edit`)
        })}
        {...(permissions.delete && {
          deleteBtnClick: () =>
            setEpicToDelete(epic?.data as AdminEpicEpicIdGetResponse)
        })}
        backBtnClick={() => navigate("/epics")}
      />
      <Loader
        isLoading={
          isCompanyDirectoryLoading ||
          (platformFilter === "ALL" && resourceFilter === "ALL" && epicLoading)
        }
      >
        <Form>
          <Grid container direction="column" spacing="25px">
            <Grid xs={12} data-testid="epic-name">
              <FormInput
                name="name"
                control={control}
                label="Epic Name"
                disabled
                required
                rules={{
                  required: "Epic Name Is Required"
                }}
                type="text"
              />
            </Grid>
            <Grid xs={12} data-testid="epic-description">
              <FormInput
                name="description"
                control={control}
                label="Description"
                disabled
                required
                rules={{
                  required: "Description Is Required"
                }}
                multiline
                rows={5}
                type="text"
              />
            </Grid>
            <Grid>
              <ButtonGroup
                variant="outlined"
                style={{ border: "rgba(215, 221, 229, 1)" }}
              >
                {(buttonGroups as ["Overview" | "Team Breakdown"]).map((bg) => {
                  return (
                    <Button
                      key={bg}
                      variant="outlined"
                      style={
                        tab === bg
                          ? {
                              backgroundColor: "#E8EEFF",
                              color: "#2B337A",
                              fontSize: "14px",
                              fontWeight: 600,
                              textTransform: "none"
                            }
                          : {
                              color: "#666666",
                              fontSize: "14px",
                              fontWeight: 500,
                              textTransform: "none"
                            }
                      }
                      onClick={() => {
                        setTab(bg);
                      }}
                    >
                      {bg}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Grid>
            <Grid container spacing={3}>
              <Loader
                isLoading={
                  teamBreakdownLoading ||
                  ((platformFilter !== "ALL" || resourceFilter !== "ALL") &&
                    epicLoading)
                }
              >
                {tab === "Overview" && (
                  <Overview
                    platformOptions={platformOptions}
                    resourcesOptions={resourcesOptions}
                    platformFilter={platformFilter}
                    resourceFilter={resourceFilter}
                    setPlatformFilter={setPlatformFilter}
                    setResourceFilter={setResourceFilter}
                    breakdown={
                      [
                        epic?.data.breakdown
                      ] as AdminEpicEpicIdGetResponseBreakdown[]
                    }
                    issues={epic?.data.issues as ModelEpicIssue[]}
                    insights={epic?.data.insights}
                  />
                )}
                {tab === "Team Breakdown" && (
                  <TeamBreakdown
                    setTab={setTab}
                    data={teamBreakdown?.data}
                    setResourceFilter={setResourceFilter}
                  />
                )}
              </Loader>
            </Grid>
          </Grid>
        </Form>
      </Loader>

      <ConfirmationDialog
        open={!!epicToDelete}
        title="Delete Epic?"
        body={`Are you sure you want to delete ${epicToDelete?.name}?`}
        close={() => setEpicToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setEpicToDelete(null)}
        isConfirming={isLoading}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
