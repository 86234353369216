import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { hasPermission } from "@services/Casbin";
import { getLeads } from "@services/Network";
import {
  ModelDemoSession,
  ModelLead,
  ModelOpportunity,
  useAdminCrmLeadGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, styled, Typography } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import { LEAD_SOURCES, LEAD_STATUS } from "@utils/constants";
import { UnLinkIcon } from "@components/Icons";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const StyledLink = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Leads = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const onAdd = () => navigate("/crm/leads/create");
  const onEdit = (lead) => navigate(`/crm/leads/${lead.leadId}/edit`);
  const onView = (lead) => navigate(`/crm/leads/${lead.leadId}`);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.leads", "ADD");
      const view = await checkPermission("crm.leads", "VIEW");
      const edit = await checkPermission("crm.leads", "EDIT");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.leads", "ADD");
      const view = await checkPermission("crm.leads", "VIEW");
      const edit = await checkPermission("crm.leads", "EDIT");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const { data: leads } = useAdminCrmLeadGet({
    userLeads: true
  });

  const LEADS_COLUMNS: GridColDef<
    ModelLead & { demoDetail: ModelDemoSession }
  >[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0" }}>
            {permissions?.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Lead" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions?.edit && (
              <IconButton onClick={() => onEdit(params.row)}>
                <ToolTip title="Edit Lead" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Name",
      field: "name",
      renderHeader: () => <div style={{ paddingLeft: "3px" }}>Name</div>,
      minWidth: 300,
      flex: 1
    },
    {
      headerName: "Source",
      field: "source",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        LEAD_SOURCES.find((ls) => ls.value === row.source?.type)?.label || ""
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        LEAD_STATUS.find((ls) => ls.value === row.status)?.label || ""
    },
    {
      headerName: "Account",
      field: "primary",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid container direction="row" spacing="5px">
            <Grid item maxWidth="75%">
              {params.row.account?.name ||
                params.row.demoDetail?.organizationName}
            </Grid>
            {!params.row.accountId && (
              <Grid item alignSelf="center">
                <UnLinkIcon />
              </Grid>
            )}
          </Grid>
        );
      }
    },
    {
      headerName: "Opportunity",
      field: "opportunity",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (params.row?.opportunities && params.row?.opportunities.length > 0) {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {params.row.opportunities.map((opportunity: ModelOpportunity) => (
                <StyledLink
                  key={opportunity.opportunityId}
                  onClick={() => {
                    navigate(`/crm/opportunities/${opportunity.opportunityId}`);
                  }}
                >
                  {opportunity.name}
                </StyledLink>
              ))}
            </div>
          );
        } else {
          return "-";
        }
      }
    }
  ];

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      ...LEAD_STATUS,
      ...(leads?.data.numRows && leads?.data?.numRows > 0
        ? [
            {
              label: "My Daily Plan",
              value: "daily_plan"
            }
          ]
        : [])
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Leads"
          useGet={getLeads}
          columns={LEADS_COLUMNS}
          getRowId={(row) => row.leadId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
        />
      </Loader>
    </>
  );
};
