import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import { CRM_ACCOUNT_CATEGORIES, CRM_ACCOUNT_TYPES } from "@utils/constants";
import { websiteClick } from "@utils/openWebsite";
import { UseFormReturn } from "react-hook-form";
import { SearchAddAccount } from "./SearchAddAccount";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  ModelActivityRelatesTo,
  useAdminSportGet,
  useAdminUserCrmGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Search } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import formatFullName from "@utils/formatFullName";
import { FullName } from "@utils/types";
import { Autocomplete } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { getCountryFromAddress } from "@utils/getCountryFromAddress";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autocompleteService = { current: null } as any;

export const AccountDetailsForm = ({
  disabled,
  form,
  setSelectedAccount,
  setIsNewAccountSelected,
  hideNoOfAthletes,
  shouldEnable,
  account,
  relatesTo,
  relatesToId,
  organizationId,
  onAccountChange,
  setSelectedCountry
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  setSelectedAccount;
  setIsNewAccountSelected;
  hideNoOfAthletes?: boolean;
  shouldEnable?: boolean;
  account?: string;
  relatesTo?: ModelActivityRelatesTo;
  relatesToId?: string;
  onAccountChange?: (account) => void;
  organizationId?: string;
  setSelectedCountry?: (val: string) => void;
}) => {
  const { setEmailTo } = useContext(CallEmailTemplateContext);
  const { control, setValue, getValues, trigger } = form;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get("accountId");
  const { data: sportsData, isLoading: isSportsLoading } = useAdminSportGet();
  const { data: aeOwnerOptions, isLoading: isLoadingAeOwnerOptions } =
    useAdminUserCrmGet({
      ...(organizationId
        ? {
            organizationId: organizationId
          }
        : {
            type: "aeOwner"
          })
    });
  const [aeOwners, setAeOwners] = useState<{ label: string; value: string }[]>(
    []
  );
  const [officeAddessSelected, setOfficeAddessSelected] = useState(true);

  useEffect(() => {
    if (aeOwnerOptions?.data) {
      setAeOwners(
        aeOwnerOptions.data.map((o) => ({
          label: formatFullName(o as FullName),
          value: o.userId!
        }))
      );
    }
  }, [aeOwnerOptions]);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;

    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));

    let address: string = "";

    if (address1 && !address1.includes("undefined")) address = `${address1}, `;
    if (city?.long_name && city?.long_name != "undefined")
      address += `${city?.long_name}, `;
    if (state?.short_name && state?.short_name != "undefined")
      address += `${state?.short_name}, `;
    if (zip?.long_name && zip?.long_name != "undefined")
      address += `${zip?.long_name}, `;
    if (country?.short_name && country?.short_name != "undefined")
      address += `${country?.short_name}`;
    setValue("account.officeAddress", address);
    if (officeAddessSelected) {
      trigger("account.officeAddress");
    }
  };
  return (
    <Grid item container direction="column" spacing="25px">
      <Grid item container xs={12} direction="column">
        <Grid item>
          <Typography
            variant="permissionNames"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              letterSpacing: "10%",
              lineHeight: "14.52px",
              opacity: "50%"
            }}
          >
            ACCOUNT DETAILS
          </Typography>
        </Grid>

        <HeaderUnderLine width="100%" />
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          {!disabled || shouldEnable ? (
            <SearchAddAccount
              disabled={!!accountId}
              accountSelected={(
                selectedAccount,
                accountSelected,
                newAccountSelected
              ) => {
                setIsNewAccountSelected(newAccountSelected);
                if (!newAccountSelected) {
                  setSelectedAccount(selectedAccount.accountId);
                  setValue(
                    "account",
                    {
                      name: selectedAccount.account,
                      parent: selectedAccount.parent,
                      category: selectedAccount.category,
                      type: selectedAccount.type,
                      officeAddress: selectedAccount.workLocation,
                      email: selectedAccount.workEmail,
                      website: selectedAccount.website,
                      sportsOffered: selectedAccount.sportsOffered,
                      aeOwner: selectedAccount.aeOwner,
                      ...(!organizationId &&
                        !hideNoOfAthletes && {
                          noOfAthletes: selectedAccount.numberOfAthletes
                        })
                    },
                    {
                      shouldDirty: true,
                      shouldValidate: true
                    }
                  );
                  if (setSelectedCountry)
                    setSelectedCountry(
                      getCountryFromAddress(selectedAccount.workLocation) ||
                        "US"
                    );
                  if (!organizationId) {
                    setValue(
                      "account.sportsOffered",
                      [...selectedAccount.sportsOffered.map((s) => s)],
                      {
                        shouldDirty: true
                      }
                    );
                  }
                  if (onAccountChange) {
                    onAccountChange(selectedAccount);
                  }
                }
              }}
              required
              showAddAccountOption={true}
              searchValue={getValues()?.account?.name || undefined}
              organizationId={organizationId}
            />
          ) : (
            <FormInput
              name="account.name"
              control={control}
              type="text"
              required
              label="Account Name"
              disabled={disabled || !!accountId}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {disabled || !shouldEnable ? (
            <FormInput
              name="account.parent"
              control={control}
              type="text"
              label="Account Parent"
              disabled={!shouldEnable}
            />
          ) : (
            <SearchAddAccount
              accountSelected={(selectedAccount) => {
                setValue("account.parent", selectedAccount.accountId, {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              showAddAccountOption={false}
              label="Parent"
              organizationId={organizationId}
            />
          )}
        </Grid>
      </Grid>

      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <FormSelect
            name="account.category"
            control={control}
            label="Account Category"
            options={CRM_ACCOUNT_CATEGORIES}
            disabled={!shouldEnable}
            required
            rules={{ required: "Category is required" }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name="account.type"
            control={control}
            label="Account Type"
            options={CRM_ACCOUNT_TYPES}
            disabled={!shouldEnable}
            required
            rules={{ required: "Type is required" }}
          />
        </Grid>
      </Grid>

      <Grid item>
        {disabled || !shouldEnable ? (
          <FormInput
            name="account.officeAddress"
            label="Account Address"
            control={control}
            type="text"
            rules={{ required: "Office Address is required" }}
            disabled={!shouldEnable}
            required
          />
        ) : (
          <Grid xs={12}>
            <Autocomplete
              key={"officeAddress"}
              className="address-autocomplete"
              onLoad={(autocomplete) => {
                autocompleteService.current = autocomplete;
              }}
              onPlaceChanged={() => {
                if (autocompleteService.current) {
                  const place = autocompleteService.current.getPlace();
                  selectAddressSuggestion(place);
                  setOfficeAddessSelected(true);
                }
              }}
            >
              <FormInput
                name="account.officeAddress"
                control={control}
                rules={{
                  required: "Account Address is required",
                  validate: () => {
                    return !officeAddessSelected
                      ? "The address entered is invalid, please make a recommended selection"
                      : undefined;
                  }
                }}
                label="Account Address"
                required
                type="text"
                onChange={() => {
                  setOfficeAddessSelected(false);
                }}
              />
            </Autocomplete>
          </Grid>
        )}
      </Grid>

      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <FormInput
            name="account.email"
            label="Account Email"
            control={control}
            type="text"
            disabled={!shouldEnable}
            required
            rules={{ required: "Email is required" }}
            InputProps={{
              ...(!organizationId &&
                disabled && {
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setEmailTo({
                          to: control._formValues?.account.email,
                          name: control._formValues?.account.name,
                          accountId: account,
                          relatesTo: relatesTo || "ACCOUNT",
                          opportunityId:
                            relatesTo === "OPPORTUNITY"
                              ? relatesToId
                              : undefined,
                          leadId:
                            relatesTo === "LEAD" ? relatesToId : undefined,
                          accountName: control._formValues?.account.name
                        })
                      }
                    >
                      <EmailIcon style={{ color: "#007AFF" }} />
                    </IconButton>
                  )
                })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="account.website"
            label="Account Website"
            control={control}
            type="text"
            disabled={!shouldEnable}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() =>
                    websiteClick(getValues()?.account?.website || "")
                  }
                >
                  <LaunchIcon style={{ color: "#007AFF" }} />
                </IconButton>
              )
            }}
          />
        </Grid>
        {!organizationId && (
          <Grid item xs={12}>
            <FormMultiSelect
              name="account.sportsOffered"
              control={control}
              label="Sports Offered"
              options={
                sportsData?.data?.map((sp) => ({
                  label: sp.name!,
                  value: sp.sportId!
                })) || []
              }
              disabled={!shouldEnable}
              isLoading={isSportsLoading}
            />
          </Grid>
        )}

        {!organizationId && !hideNoOfAthletes && (
          <Grid item xs={6}>
            <FormInput
              name="account.noOfAthletes"
              control={control}
              label="No. of Yearly Athlete Registrations"
              disabled={!shouldEnable}
              type="number"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          {disabled || !shouldEnable ? (
            <FormInput
              name="account.aeOwner"
              control={control}
              label={organizationId ? "Account Owner" : "AE Owner"}
              disabled
              type="text"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          ) : (
            <FormSelect
              control={control}
              name="account.aeOwner"
              type="text"
              label={organizationId ? "Account Owner" : "AE Owner"}
              isLoading={isLoadingAeOwnerOptions}
              options={aeOwners}
              required
              rules={{
                required: `${
                  organizationId ? "Account" : "AE"
                } Owner is required`
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
