import React, { useState } from "react";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { Box, styled, Typography } from "@mui/material";
import { ModelStream } from "@sportsgravyengineering/sg-api-react-sdk";
import { LiveStreamPlayer } from "@pages/live-stream/LiveStreamPlayer";
import { CompletedLiveStreamPlayer } from "@pages/live-stream/CompletedLivestreamPlayer";

const LiveStreamBox = styled(Box)`
  margin-top: 12px;
  background-color: #efefef;
  border-radius: 6px;
  width: 100%;
  .title {
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    display: block;
    padding: 15px;
  }
  .time {
    color: #666;
    position: relative;
    font-weight: 400;
    padding-left: 15px;
    top: -15px;
    font-size: 12px;
    line-height: 16px;
  }
  div {
    background-color: #efefef;
  }
  div .status {
    height: 26px;
    position: absolute;
    bottom: 20px;
    left: 16px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
  }
  img {
    display: block;
    height: 470px;
    width: 100%;
    object-fit: cover;
    max-height: 300px;
  }
  @media (max-width: 465px) {
    .title {
      font-size: 12px;
      padding: 8px;
    }
    .time {
      padding-left: 8px;
      font-size: 10px;
    }
    img {
      max-height: 300px;
    }
  }
  @media (max-width: 357px) {
    img {
      max-height: 250px;
    }
  }
  @media (max-width: 280px) {
    img {
      max-height: 200px;
    }
  }
`;

const StyledPlayIcon = styled(PlayArrowOutlinedIcon)`
  background-color: #000000;
  opacity: 0.75;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const livestreamDateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

export const LivestreamPost = ({ livestream }: { livestream: ModelStream }) => {
  const [viewLive, setViewLive] = useState<"LIVE" | "COMPLETED" | undefined>(
    undefined
  );
  return (
    <>
      <LiveStreamBox>
        <Box
          style={{
            position: "relative",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px"
          }}
        >
          <img
            src={
              livestream?.thumbnail?.baseUrl && livestream?.thumbnail?.path
                ? livestream?.thumbnail?.baseUrl + livestream?.thumbnail?.path
                : ""
            }
          />
          {["LIVE", "INTERMISSION", "PAUSED", "COMPLETED"].includes(
            livestream?.status as string
          ) && (
            <StyledPlayIcon
              fontSize="large"
              cursor={"pointer"}
              onClick={() =>
                setViewLive(
                  livestream?.status === "COMPLETED" ? "COMPLETED" : "LIVE"
                )
              }
            />
          )}
          {["LIVE", "INTERMISSION", "PAUSED"].includes(
            livestream?.status as string
          ) && (
            <div
              className="status"
              style={{
                backgroundColor: "#e82c2c",
                color: "#fff"
              }}
            >
              LIVE
            </div>
          )}
          {["CANCELLED"].includes(livestream?.status as string) && (
            <div
              className="status"
              style={{ backgroundColor: "#FFEEEE", color: "#A11212" }}
            >
              Cancelled
            </div>
          )}
        </Box>
        <Typography className="title">{livestream?.title}</Typography>
        {["NOT_STARTED", "DELAYED", "POSTPONED"].includes(
          livestream?.status as string
        ) && (
          <Typography className="time">
            {livestreamDateFormat(
              livestream?.status == "NOT_STARTED"
                ? livestream?.scheduledAt
                : livestream?.delayedUntil
            )}
          </Typography>
        )}
      </LiveStreamBox>
      {viewLive === "LIVE" && (
        <LiveStreamPlayer
          handleViewLiveClose={() => setViewLive(undefined)}
          liveStream={livestream}
        />
      )}
      {viewLive === "COMPLETED" && (
        <CompletedLiveStreamPlayer
          onClose={() => setViewLive(undefined)}
          liveStream={livestream}
        />
      )}
    </>
  );
};
