import { Loader } from "@components/crud/Loader";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormSelect } from "@components/FormSelect";
import { FormMultiSelect } from "@components/FormMultiSelect";
import {
  ModelJobTitle,
  useAdminJobTitleGet,
  useAdminRoleGet,
  useConfigGet,
  useConfigPut,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { CRMApprovalSettings, OrderApproval } from "./CRMApprovalSettings";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { DeleteIcon } from "@components/Icons";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Add } from "@mui/icons-material";
import colors from "theme/colors";
import { CRMAccountSettings } from "./CRMAccountSettings";
import { SearchInput } from "@components/SearchInput";
import React from "react";

export type Tier = {
  id: number;
  startAthlete: string;
  endAthlete: string;
  price: string;
  setupPrice: string;
  countryId?: string;
};

export const CRMSetting = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const { reset, control, getValues, setValue } = form;
  const { data: settings, isLoading: isLoading } = useConfigGet();
  const { data: roles, isLoading: isRoleLoading } = useAdminRoleGet({
    type: "SYSTEM",
    includeChildren: true,
    pageSize: "100"
  });
  const EmailToOptions = useMemo(() => {
    const getOptions = (roles) => {
      return roles
        .map((role) => {
          let options = [
            {
              label: role.name,
              value: role.roleId
            }
          ];

          if (role.children) {
            options = [...options, ...getOptions(role.children)];
          }

          return options;
        })
        .flat();
    };

    if (roles && roles.data && roles.data.roles) {
      return [
        {
          value: "LEAD_OWNER",
          label: "Lead owner"
        },
        ...getOptions(roles.data.roles)
      ];
    }

    return [];
  }, [roles]);

  const { data: jobs, isLoading: isJobsLoading } = useAdminJobTitleGet({
    pageSize: 100
  });

  const JobOptions = useMemo(() => {
    const getOptions = (jobs: ModelJobTitle[]) => {
      return jobs
        .map((job) => {
          const options = [
            {
              label: job.name!,
              value: job.jobtitleId!
            }
          ];
          return options;
        })
        .flat();
    };

    if (jobs && jobs.data && jobs.data.jobTitles) {
      return getOptions(jobs.data.jobTitles);
    }

    return [];
  }, [jobs]);
  const [orderApprovals, setOrderApprovals] = useState<OrderApproval[]>([
    {
      job: "",
      amount: "1000"
    }
  ]);
  const [priceTiers, setPriceTiers] = useState<OrderApproval[]>([]);
  const [pricingTierCountry, setPricingTierCountry] = useState("US");
  const [pricingTierCountryInputValue, setPricingTierCountryInputValue] =
    useState("United States");
  const { data: countriesResponse, isFetching: isFetchingCountries } =
    useLookupCountryGet({
      query: {
        staleTime: Infinity
      }
    });
  const countries = React.useMemo(() => {
    return countriesResponse?.data.map((country) => {
      return {
        label: country.name as string,
        value: country.countryId as string
      };
    });
  }, [countriesResponse]);

  const [rows, setRows] = useState<Tier[]>([]);

  const [toDelete, setToDelete] = useState<
    | {
        index: number;
        tier: Tier;
      }
    | undefined
  >(undefined);

  const [pricingTierInvalid, setPricingTierInvalid] = useState<
    string | undefined
  >();
  useEffect(() => {
    if (settings && settings.data) {
      const orderApprovalsData = (settings.data.find(
        (item) => item.key === "crm.order.approvals-required"
      )?.value || []) as OrderApproval[];
      const priceTierApprovalsData = (settings.data.find(
        (item) => item.key === "crm.opportunity.pricing-tier-approval-required"
      )?.value || []) as OrderApproval[];
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      const approvals =
        orderApprovalsData?.reduce((acc, link, idx) => {
          const key = `amount.job${idx}`;
          acc[key] = link.job;
          if (link.amount) acc[`amount.amount${idx}`] = link.amount;
          return acc;
        }, {}) || {};
      const priceTierApprovals =
        priceTierApprovalsData?.reduce((acc, link, idx) => {
          const key = `percentage.job${idx}`;
          acc[key] = link.job;
          if (link.percentage)
            acc[`percentage.percentage${idx}`] = link.percentage;
          return acc;
        }, {}) || {};
      const ratings =
        (settings.data.find((item) => item.key === "crm.account.org-rating")
          ?.value as { type: string; start: number; end: string | number }[]) ||
        [];
      reset({
        autoGenerateRenewalOpp: settings.data.find(
          (item) => item.key === "crm.opportunity.generate-renewal-days"
        )?.value,
        newMQLEmailNotif: settings.data.find(
          (item) => item.key === "crm.lead.new-mql-email"
        )?.value,
        defaultLeadOwner: settings.data.find(
          (item) => item.key === "crm.lead.default-lead-owner"
        )?.value,
        rating: {
          AAA: ratings.find((rating) => rating.type === "AAA")?.start + " +",
          AA:
            ratings.find((rating) => rating.type === "AA")?.start +
            " - " +
            ratings.find((rating) => rating.type === "AA")?.end,
          A:
            ratings.find((rating) => rating.type === "A")?.start +
            " - " +
            ratings.find((rating) => rating.type === "A")?.end,

          B:
            ratings.find((rating) => rating.type === "B")?.start +
            " - " +
            ratings.find((rating) => rating.type === "B")?.end,
          C:
            ratings.find((rating) => rating.type === "C")?.start +
            " - " +
            ratings.find((rating) => rating.type === "C")?.end
        },
        ...approvals,
        ...priceTierApprovals,
        pricingTierCountry: "US"
      });

      setOrderApprovals(orderApprovalsData);
      setPriceTiers(priceTierApprovalsData);
      setRows(priceTiersList);
    }
  }, [settings]);

  const { mutate: save, isLoading: isSaving } = useConfigPut();
  const onSave = (key: string, value) => {
    if (settings?.data) {
      save(
        {
          data: [{ key, value }]
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Saved Successfully!", {
              variant: "success"
            });
          },
          onError: () => {
            enqueueSnackbar("Failed to save !", {
              variant: "error"
            });
          }
        }
      );
    }
  };
  const validatePricingTiersAndSave = (pricingTiers: Tier[]) => {
    setPricingTierInvalid(undefined);
    const countryPricingTier = pricingTiers.filter(
      (p) => p.countryId === pricingTierCountry
    );
    if (countryPricingTier.length === 0) return;

    for (let i = 0; i < countryPricingTier.length - 1; i++) {
      const currentTier = countryPricingTier[i];
      const nextTier = countryPricingTier[i + 1];

      // Convert startAthlete and endAthlete to integers
      const currentStart = parseInt(currentTier.startAthlete, 10);
      const currentEnd = parseInt(currentTier.endAthlete, 10);
      const nextStart = parseInt(nextTier.startAthlete, 10);
      const nextEnd = parseInt(nextTier.endAthlete, 10);

      // Check if all required fields are present
      if (
        !(
          currentTier.price &&
          currentTier.startAthlete &&
          currentTier.endAthlete &&
          currentTier.setupPrice
        )
      )
        return;

      // Check if the current end overlaps with the next start
      if (currentEnd >= nextStart) {
        setPricingTierInvalid(
          `Invalid range: ${currentStart}-${currentEnd} overlaps with ${nextStart}-${nextEnd}`
        );
        return;
      }

      // Check if the ranges are continuous
      if (currentEnd + 1 !== nextStart) {
        setPricingTierInvalid(
          `Invalid range: ${currentStart}-${currentEnd} overlaps with ${nextStart}-${nextEnd}`
        );
        return;
      }
    }

    // If all checks pass, save the pricing tiers
    onSave("crm.order.pricing-tier", pricingTiers);
  };
  return (
    <>
      <Grid item container direction="column" spacing="20px">
        <Loader
          isLoading={
            isLoading || isRoleLoading || isJobsLoading || isFetchingCountries
          }
        >
          <Grid item container xs={12} direction="column">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                LEAD SETTINGS
              </Typography>
            </Grid>

            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormSelect
                control={control}
                disabled
                name="defaultLeadOwner"
                options={[
                  {
                    label: "Automatically assign based on territory",
                    value: "AUTO_ASSIGN_TERRITORY"
                  }
                ]}
                label="Default Lead Owner"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormMultiSelect
                control={control}
                name="newMQLEmailNotif"
                options={EmailToOptions}
                label="New MQL Email Notification"
                required
                onChange={(e) => {
                  setValue("newMQLEmailNotif", e.target.value);
                  onSave("crm.lead.new-mql-email", e.target.value);
                }}
                onRemove={(value) => {
                  const updatedValues = getValues().newMQLEmailNotif.filter(
                    (role) => role !== value
                  );
                  setValue("newMQLEmailNotif", updatedValues);
                  onSave("crm.lead.new-mql-email", updatedValues);
                }}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} direction="column">
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px",
                  opacity: "50%"
                }}
              >
                OPPORTUNITY SETTINGS
              </Typography>
            </Grid>

            <HeaderUnderLine width="100%" />
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormSelect
                control={control}
                name="autoGenerateRenewalOpp"
                options={[
                  {
                    label: "30 days before subscription end date",
                    value: "30D"
                  },
                  {
                    label: "60 days before subscription end date",
                    value: "60D"
                  },
                  {
                    label: "90 days before subscription end date",
                    value: "90D"
                  }
                ]}
                label="Auto Generate Renewal Opportunities"
                required
                onChange={(e) => {
                  onSave(
                    "crm.opportunity.generate-renewal-days",
                    e.target.value
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="column">
            <Grid item marginTop="10px" marginBottom="-5px">
              <StyledFormLabel required>
                <Typography variant="formLabel">
                  Approval Required For Order Amounts Greater Than
                </Typography>
              </StyledFormLabel>
            </Grid>
            <Grid item marginTop="20px">
              <CRMApprovalSettings
                key="crm.order.approvals-required"
                orderApprovals={orderApprovals}
                setOrderApprovals={setOrderApprovals}
                control={control}
                jobOptions={JobOptions}
                onSave={(val) => {
                  const convertedFilValues = val
                    .filter((v) => v.amount && v.job)
                    .map((v) => ({
                      job: v.job,
                      amount: v.amount!.replace(/,/g, "")
                    }));
                  onSave("crm.order.approvals-required", convertedFilValues);
                }}
                newApproval={{ job: "", amount: "10000" }}
                inputField={{
                  name: "amount",
                  rules: {
                    required: "Amount is required"
                  },
                  formatCurrency: true,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item marginTop="24px">
              <StyledFormLabel required>
                <Typography variant="formLabel">
                  Approval Required for Changing Pricing Tier
                </Typography>
              </StyledFormLabel>
            </Grid>
            <Grid item marginTop="20px">
              <CRMApprovalSettings
                key="crm.opportunity.pricing-tier-approval-required"
                orderApprovals={priceTiers}
                setOrderApprovals={setPriceTiers}
                control={control}
                jobOptions={JobOptions}
                onSave={(val) => {
                  const convertedFilValues = val
                    .filter((v) => v.percentage && v.job)
                    .map((v) => ({
                      job: v.job,
                      percentage: v.percentage!.replace(/,/g, "")
                    }));
                  onSave(
                    "crm.opportunity.pricing-tier-approval-required",
                    convertedFilValues
                  );
                }}
                newApproval={{ job: "", percentage: "0" }}
                inputField={{
                  name: "percentage",
                  rules: {
                    required: "Value is required",
                    validate: (value) => {
                      if (parseFloat(value) > 100) {
                        return "Value should be less than 100";
                      }
                      return true;
                    }
                  },
                  formatCurrency: false,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item container xs={12} direction="column" marginTop="20px">
              <Grid item>
                <Typography
                  variant="permissionNames"
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "10%",
                    lineHeight: "14.52px",
                    opacity: "50%"
                  }}
                >
                  COUNTRY PRICING TIERS
                </Typography>
              </Grid>

              <HeaderUnderLine width="100%" />
            </Grid>
            <Grid item container direction="column" mt="15px">
              <Autocomplete
                clearOnBlur={false}
                options={countries || []}
                inputValue={pricingTierCountryInputValue}
                renderInput={(params) => {
                  return (
                    <SearchInput
                      data-testid="SEARCH_ORGANIZATION_INPUT"
                      {...params}
                      value={pricingTierCountryInputValue}
                      onChange={(e) => {
                        setPricingTierCountryInputValue(e.target.value);
                      }}
                      onBlur={() => {
                        const country = countries?.find((country) =>
                          pricingTierCountry
                            ? country.value === pricingTierCountry
                            : country.value === "US"
                        );
                        setPricingTierCountryInputValue(country?.label || "");
                      }}
                      placeholder="Search for Countries"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                      }}
                    />
                  );
                }}
                renderOption={(
                  props,
                  option: { value: string | undefined; label: string }
                ) => {
                  return (
                    <MenuItem
                      data-testId={`COUNTRY_OPTION_${option.value}`}
                      key={option.value}
                      id={option.value}
                      selected={option.value === pricingTierCountry}
                      disabled={option.value === pricingTierCountry}
                      onClick={() => {
                        setPricingTierCountryInputValue(option.label);
                        setPricingTierCountry(option.value!);
                      }}
                    >
                      <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                  );
                }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              marginTop="24px"
              spacing="10px"
              columns={7}
            >
              <Grid item container direction="row" gridColumn={1}>
                <Grid item xs={6}>
                  <StyledFormLabel required>
                    <Typography variant="formLabel">
                      Pricing Tiers For Yearly Athlete Registrations
                    </Typography>
                  </StyledFormLabel>
                </Grid>
                <Grid item xs={2.9}></Grid>
                <Grid item xs={3}>
                  <StyledFormLabel required>
                    <Typography variant="formLabel">
                      Onetime Setup Fee
                    </Typography>
                  </StyledFormLabel>
                </Grid>
              </Grid>
              {rows
                .filter((r) => r.countryId === pricingTierCountry)
                .map((tier) => (
                  <Grid
                    item
                    container
                    direction="row"
                    key={tier.id + pricingTierCountry}
                  >
                    <Grid item xs={2.6}>
                      <FormInput
                        control={control}
                        label=""
                        name="startAthlete"
                        type="text"
                        enteredValue={tier.startAthlete.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  startAthlete: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography style={{ color: "#B3B3B3" }}>to</Typography>
                    </Grid>
                    <Grid item xs={2.6}>
                      <FormInput
                        control={control}
                        label=""
                        name="endAthlete"
                        type="text"
                        enteredValue={tier.endAthlete.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  endAthlete: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography style={{ color: "#B3B3B3" }}>at</Typography>
                    </Grid>
                    <Grid item xs={2.6}>
                      <FormInput
                        control={control}
                        label=""
                        name="price"
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        enteredValue={tier.price.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  price: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={2.6} marginLeft="10px">
                      <FormInput
                        control={control}
                        label=""
                        name="setupPrice"
                        type="text"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        enteredValue={tier.setupPrice.toString()}
                        onChange={(e) => {
                          const value = (e as ChangeEvent<HTMLInputElement>)
                            .target.value;
                          const values = rows.map((row) =>
                            row.id === tier.id
                              ? {
                                  ...row,
                                  setupPrice: value
                                }
                              : row
                          );
                          setRows(values);
                          validatePricingTiersAndSave(values);
                        }}
                        TextProps={{
                          onInput: (e) => {
                            const input = e as ChangeEvent<HTMLInputElement>;
                            input.target.value = input.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const parts = input.target.value.split(".");
                            if (parts.length > 2) {
                              input.target.value =
                                parts[0] + "." + parts.slice(1).join("");
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      paddingLeft="15px"
                    >
                      <div
                        style={{
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #E5E5E5",
                          height: "40px",
                          width: "40px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setToDelete({ index: tier.id, tier: tier });
                        }}
                      >
                        <DeleteIcon height="24px" width="24px" />
                      </div>
                    </Grid>
                  </Grid>
                ))}
              {pricingTierInvalid && (
                <Grid item style={{ marginTop: "-10px" }}>
                  <Typography style={{ color: "#E82C2C" }} variant="body2">
                    {pricingTierInvalid}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid marginTop="16px">
              <Button
                startIcon={<Add />}
                style={{
                  textTransform: "capitalize",
                  color: colors.primary.main
                }}
                onClick={() => {
                  const previousEnd = rows?.[rows.length - 1]?.endAthlete;
                  setRows([
                    ...rows,
                    {
                      id: Math.round(Math.random() * 1000),
                      startAthlete: previousEnd
                        ? String(parseInt(previousEnd) + 1)
                        : "0",
                      endAthlete: "",
                      price: "0",
                      setupPrice: "0",
                      countryId: pricingTierCountry
                    }
                  ]);
                }}
              >
                Add Pricing Tier
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="column">
            <CRMAccountSettings
              onSave={(values) => onSave("crm.account.org-rating", values)}
              form={form}
              isLoading={isSaving}
            />
          </Grid>
        </Loader>
      </Grid>
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Order Approval"
        body={`Are you sure you want to delete Tier`}
        close={() => setToDelete(undefined)}
        onConfirm={() => {
          const priceTier = rows;
          const filteredPriceTier = priceTier.filter(
            (tier) => tier.id !== toDelete?.tier.id
          );
          setRows([...filteredPriceTier]);
          validatePricingTiersAndSave(filteredPriceTier);
        }}
        onCancel={() => setToDelete(undefined)}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </>
  );
};
